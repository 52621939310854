.BrainhubCarousel__arrows:disabled {
    background-color: transparent;
}

.BrainhubCarousel__arrows:disabled span {
    border-color: #6B6B6B;
}

.BrainhubCarousel__arrows {
    background-color: transparent;
}

.BrainhubCarousel__arrows:hover:enabled {
    background-color: transparent;
}

.BrainhubCarousel__arrowLeft {
    padding-left: 0;
}

.BrainhubCarousel__arrowRight {
    padding-right: 0;
}

@media (max-width: 1024px) {
    .BrainhubCarousel__arrowLeft {
        padding-right: 40px;
    }

    .BrainhubCarousel__arrowRight {
        padding-left: 40px;
    }
}